
























































import { defineComponent, PropType } from '@vue/composition-api'
import { PropertyCardMode } from './PropertyCard.vue'

export default defineComponent({
  name: 'PropertyCardSkeleton',

  props: {
    mode: {
      type: String as PropType<PropertyCardMode>,
      default: 'grid' as PropertyCardMode,
    },
  },
})
