










import appConfig from '@/app.config.json'
import router from '@/router'
import { PropertyViewModel } from '@/services/modules/property'
import ShareModal from '@/components/ShareModal.vue'
import i18n from '@/setup/i18n'
import { getPropertyRouteTo } from '@/utils/property'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyShareModal',

  components: {
    ShareModal,
  },

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    function onUpdate(newValue: boolean) {
      context.emit('update', newValue)
    }

    const title = computed(() => i18n.t('propertyShareTitle') as string)

    const shareText = computed(
      () => i18n.t('propertyShareText', { title: appConfig.title }) as string
    )

    const href = computed(
      () =>
        `${window.location.origin}${
          router.resolve(getPropertyRouteTo(props.estateProperty)).href
        }`
    )

    return {
      onUpdate,
      title,
      shareText,
      href,
    }
  },
})
