
















































































































































































































































import { useCurrency } from '@/composition/currency'
import {
  PropertyViewModel,
  PropertyCategoryId,
} from '@/services/modules/property'
import { Swiper } from 'vue-awesome-swiper'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { getPropertyRouteTo } from '@/utils/property'
import { compareListingStore } from '@/composition/compareListingStore'

export type PropertyCardMode = 'grid' | 'list'

export default defineComponent({
  name: 'PropertyCard',

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },

    mode: {
      type: String as PropType<PropertyCardMode>,
      default: 'grid' as PropertyCardMode,
    },

    updateSliderOnMounted: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = computed(() => ({
      loop: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 5,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }))

    if (props.updateSliderOnMounted) {
      onMounted(() => {
        if (!swiper.value) return
        swiper.value.updateSwiper()
      })
    }

    const propertyPrice = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyPrice2 = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price2,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyCleaningFee = computed(
      () =>
        useCurrency({
          value: props.estateProperty.cleaningFee,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const isRental = computed(
      () =>
        props.estateProperty.categoryViewModel.categoryId ===
        PropertyCategoryId.Rentals
    )

    const propertyRouteTo = computed(() =>
      getPropertyRouteTo(props.estateProperty)
    )

    function addToCompareListings() {
      compareListingStore.add(props.estateProperty)
    }

    function share() {
      context.emit('share', props.estateProperty)
    }

    return {
      swiper,
      swiperOptions,
      propertyPrice,
      propertyPrice2,
      propertyCleaningFee,
      isRental,
      propertyRouteTo,
      addToCompareListings,
      share,
    }
  },
})
