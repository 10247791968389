




































































































































































































































import appConfig from '@/app.config.json'
import i18n from '@/setup/i18n'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ShareModal',

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    shareText: {
      type: String,
      default: '',
    },

    href: {
      type: String,
      required: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const ariaLabel = computed(() => i18n.t('share') as string)

    function onUpdate(newValue: boolean) {
      context.emit('update', newValue)
    }

    function onFacebookShare() {
      openShareWindow(
        `https://www.facebook.com/dialog/share?app_id=${
          appConfig.facebook.appId
        }&href=${encodeURIComponent(props.href)}`,
        'fbShareWindow'
      )
    }

    function onTwitterShare() {
      openShareWindow(
        `https://twitter.com/intent/tweet?source=tweetbutton&text=${encodeURIComponent(
          props.shareText
        )}&url=${encodeURIComponent(props.href)}`,
        'twitterShareWindow'
      )
    }

    function onEmailShare() {
      openShareWindow(
        `mailto:?subject=${encodeURIComponent(
          props.shareText
        )}&body=${encodeURIComponent(`${props.shareText} ${props.href}`)}`,
        '_self'
      )
    }

    function onWhatsAppShare() {
      openShareWindow(
        `https://wa.me/?text=${encodeURIComponent(
          `${props.shareText} ${props.href}`
        )}`,
        '_self'
      )
    }

    function onSmsShare() {
      openShareWindow(
        `sms:?&body=${encodeURIComponent(`${props.shareText} ${props.href}`)}`,
        '_self'
      )
    }

    function onFacebookMessengerShare() {
      const encodedHref = encodeURIComponent(props.href)
      openShareWindow(
        `https://www.facebook.com/dialog/send?app_id=${appConfig.facebook.appId}&link=${encodedHref}&redirect_uri=${encodedHref}`,
        'fbMessengerShareWindow'
      )
    }

    function onCopyLink() {
      const copyText = document.querySelector('#copy-link') as HTMLInputElement
      copyText.select()
      document.execCommand('copy')
    }

    function openShareWindow(url: string, target: string) {
      window.open(
        url,
        target,
        `height=700, width=900, top=0, left=0, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
      )
    }

    return {
      ariaLabel,
      onUpdate,
      onFacebookShare,
      onTwitterShare,
      onEmailShare,
      onWhatsAppShare,
      onSmsShare,
      onFacebookMessengerShare,
      onCopyLink,
    }
  },
})
